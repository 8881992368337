import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { URLCONFIG } from "../../services/URLCONFIG";

function AddUser({ history }) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const validationErrors = validateForm(formData);

  //   if (Object.keys(validationErrors).length === 0) {
  //     try {
  //       // Make API call using Axios with the updated URL
  //       const response = await axios.post(
  //         // Update the URL below with the desired API endpoint
  //         `${URLCONFIG.ConfigBaseURL}api/users`,
  //         {
  //           accountName: formData.username,
  //           emailId: formData.email,
  //           password: formData.password,
  //         }
  //       );

  //       // Handle the response as needed
  //       console.log("API Response:", response.data);

  //       // Display success toast
  //       toast.success("Account created successfully!");

  //       // Reset the form fields
  //       setFormData({
  //         username: "",
  //         email: "",
  //         password: "",
  //         confirmPassword: "",
  //         phoneNumber: "",
  //       });

  //       // Redirect the user to the login page after a short delay
  //       setTimeout(() => {
  //         // Add your redirection logic here (e.g., history.push("/login"))
  //       }, 2000);
  //     } catch (error) {
  //       // Handle API call errors
  //       console.error("API Error:", error);

  //       // Display error toast
  //       toast.error("An error occurred while creating the account.");
  //     }
  //   } else {
  //     setErrors(validationErrors);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Get account ID from session storage
        const accountId = sessionStorage.getItem("accountId");

        // Make API call using Axios with the updated URL
        const response = await axios.post(
          // Update the URL below with the desired API endpoint
          `${URLCONFIG.ConfigBaseURL}api/users`,
          {
            accountName: formData.username,
            emailId: formData.email,
            password: formData.password,
            accountId: accountId,
            userType: "user",
            activeYN: 1,
          }
        );

        // Handle the response as needed
        console.log("API Response:", response.data);

        // Display success toast
        toast.success("Account created successfully!");

        // Reset the form fields
        setFormData({
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
          phoneNumber: "",
        });

        // Redirect the user to the login page after a short delay
        setTimeout(() => {
          // Add your redirection logic here (e.g., history.push("/login"))
        }, 2000);
      } catch (error) {
        // Handle API call errors
        console.error("API Error:", error);

        // Display error toast
        if (
          error.response &&
          error.response.data &&
          error.response.data.error === "Email ID Already Exist"
        ) {
          // If the error is due to existing email, display an alert
          alert("Email ID already exists. Please use a different email.");
        } else {
          // For other errors, display a generic error toast
          toast.error("An error occurred while creating the account.");
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.username) {
      errors.username = "Username is required";
    }

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email format";
    }

    if (!data.password) {
      errors.password = "Password is required";
    }

    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Check if the email has a valid format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    // Custom domain validation: Check if the email contains a dot after '@'
    if (!emailRegex.test(email)) {
      return false;
    }

    return true;
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          error={errors.username ? true : false}
          helperText={errors.username}
          fullWidth
          margin="normal"
          size={"small"}
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={errors.email ? true : false}
          helperText={errors.email}
          fullWidth
          margin="normal"
          size={"small"}
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          error={errors.password ? true : false}
          helperText={errors.password}
          fullWidth
          margin="normal"
          size={"small"}
        />
        <TextField
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={errors.confirmPassword ? true : false}
          helperText={errors.confirmPassword}
          fullWidth
          margin="normal"
          size={"small"}
        />
        <TextField
          label="Phone Number"
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          error={errors.phoneNumber ? true : false}
          helperText={errors.phoneNumber}
          fullWidth
          margin="normal"
          size={"small"}
        />
        <Box my={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
          >
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default AddUser;
