export const URLCONFIG = {
  ConfigBaseURL: "http://3.108.228.74:8100/",
  // ServiceMasterBaseURL:'https://niyxmoxjml.execute-api.us-east-1.amazonaws.com/dev/',
  ServiceMasterBaseURL:
    "https://ljok0y0jq3.execute-api.us-east-1.amazonaws.com/dev/",
  // PolicyBaseURL:'https://ex3qgoh3rk.execute-api.us-east-1.amazonaws.com/dev/',
  PolicyBaseURL: "https://87c7bjighi.execute-api.us-east-1.amazonaws.com/dev/",
  // RoleBaseURL:' https://f2c6vwskpg.execute-api.us-east-1.amazonaws.com/dev/',
  RoleBaseURL: "https://yg67wl3v76.execute-api.us-east-1.amazonaws.com/dev/",
  // UserGroupsURL:'https://ndlhbajc15.execute-api.us-east-1.amazonaws.com/dev/',
  UserGroupsURL: "https://tv177w9gka.execute-api.us-east-1.amazonaws.com/dev/",
  // UserURL:'https://98mmkghcmh.execute-api.us-east-1.amazonaws.com/dev/',
  UserURL: " https://apo3dvgnie.execute-api.us-east-1.amazonaws.com/dev/",
  // ProjectURL:'https://wnecpwovji.execute-api.us-east-1.amazonaws.com/dev/',
  ProjectURL: "https://jl2gmb4lcj.execute-api.us-east-1.amazonaws.com/dev/",
  // BillingSystemURL:'https://ch4sf5c6re.execute-api.us-east-1.amazonaws.com/Prod/',
  BillingSystemURL:
    "https://y3anb1qsfk.execute-api.us-east-1.amazonaws.com/dev/",
  // SnowFlakeIdGeneration:'https://e23382x1bi.execute-api.us-east-1.amazonaws.com/Prod/',
  SnowFlakeIdGeneration:
    "https://4sczrrpaud.execute-api.us-east-1.amazonaws.com/dev/",
  // NodeAPI:'https://4sczrrpaud.execute-api.us-east-1.amazonaws.com/dev/nodes',
  NodeAPI: "https://4sczrrpaud.execute-api.us-east-1.amazonaws.com/dev/",
  BuildHistoryURL:
    "https://n7530ec9s9.execute-api.us-east-1.amazonaws.com/dev/",
  StartBuildURL: "https://n7530ec9s9.execute-api.us-east-1.amazonaws.com/dev/",
  IndexURL: "https://wnzd7hfnp4.execute-api.us-east-1.amazonaws.com/Prod/",
};
