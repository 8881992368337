import React from 'react'
import { Button, FormControlLabel, Checkbox, Link, Grid } from '@mui/material';
import Copyright from '../../Common/Copyright';

const LoginCommonSection = () => {
    return (
        <>
            {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
            /> */}
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ my: 2 }}
            >
                Sign In
            </Button>
            <Grid container>
                <Grid item xs>
                    {/* <Link href="/forgotpassword" variant="body2">
                        Forgot password?
                    </Link> */}
                </Grid>
                <Grid item>
                    <Link href="/#/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                </Grid>
            </Grid>
            <Copyright />
        </>
    )
}

export default LoginCommonSection
