import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "./App.scss";
import OurTeam from "./containers/AboutUs/OurTeam";
import AppHeader from "./layouts/AppHeader/AppHeader";
import NotFound404 from "./containers/404/NotFound404";

import Pricing from "./containers/Pricing/Pricing";
import { Box, Toolbar } from "@mui/material";
import AccessDashbord from "./containers/accessManagement/Dashboard";
import Login from "./containers/login/LoginCommonSection";
import Login1 from "./containers/Login1/Login";
import SignUp from "./containers/GetStarted/SignUp";
import ForgetPassword from "./containers/Login1/ForgetPassword";
import Dashboard from "./containers/accessManagement/Dashboard";
import VisitorsEntry from "./containers/VisitorEntry/visitors";
import Reports from "./containers/reports/report";
import MyForms from "./containers/Forms/MyForms";
import AddEditForms from "./containers/Forms/AddEditForms";
import Subscription from "./containers/Subscription/Subscription";
import UserList from "./containers/Users/UserList";
import AddUser from "./containers/Users/AddUser";
import ChangePassword from "./containers/Login1/ChangePassword";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import darkTheme from "./darktheme";

function App() {
  let location = useLocation();

  const pagesNav = (
    <Box width={"100%"} className="mainContent">
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="aboutUs" element={<OurTeam />} />
        <Route path="login" element={<Login />} />
        <Route path="Login1" element={<Login1 />} />
        <Route path="Pricings" element={<Pricing />} />
        <Route path="SignUp" element={<SignUp />} />
        <Route path="forgetpassword" element={<ForgetPassword />} />
        <Route path="changePassword" element={<ChangePassword />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="visitorentry" element={<VisitorsEntry />} />
        <Route path="report" element={<Reports />} />
        <Route path="userList" element={<UserList />} />
        <Route path="addEdituser" element={<AddUser />} />
        <Route path="myForms" element={<MyForms />} />
        <Route path="addEditForm" element={<AddEditForms />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="AccessManagementDashboard" element={<AccessDashbord />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </Box>
  );
  const checkLoginSignIn =
    location.pathname === "/Login1" ||
    location.pathname === "/SignUp" ||
    location.pathname === "/";
  return (
    <ThemeProvider theme={checkLoginSignIn ? darkTheme : theme}>
      {checkLoginSignIn ? (
        pagesNav
      ) : (
        <Box width={"100%"}>
          <Toolbar variant="dense" />
          <AppHeader pagesNav={pagesNav} />
        </Box>
      )}

      {/* <AppFooter /> */}
    </ThemeProvider>
  );
}

export default App;
