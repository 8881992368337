import React from "react";
import "./style.scss";
import { Box, Button, Typography } from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <div
      className={`bannerSection ${
        props.IsHomeBanner ? "homeBanner" : "subBanner"
      }`}
      style={{ backgroundImage: `url(${props.Image})` }}
    >
      {props.IsHomeBanner ? (
        <Box textAlign={"center"}>
          <Typography
            variant="h2"
            color={"white"}
            fontWeight={"bold"}
            gutterBottom
          >
            {props.BannerHeading}
          </Typography>
          {/* <Button variant="contained" startIcon={<VolunteerActivismIcon />} size="large" component={Link} to="https://rzp.io/l/pqlTYtD" target='_blank'>
                        Donate TIET
                    </Button> */}
        </Box>
      ) : (
        <Typography variant="h2" color={"#fff"}>
          {props.BannerHeading}
        </Typography>
      )}
    </div>
  );
};

export default Banner;
