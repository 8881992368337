import React, { useEffect, useState } from 'react'
import axios from 'axios';
// import CustomLoader from '../../Common/CustomLoader';
import { URLCONFIG } from '../../services/URLCONFIG';
import { Paper, Typography, Grid, Box, Container } from '@mui/material';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';

export default function Dashboard() {
    // const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    useEffect(() => {
        const GetDashboardCount = async () => {
            try {
                await axios({
                    url: `${URLCONFIG.ConfigBaseURL}userDashboardSummary/${JSON.parse(sessionStorage.getItem('auth')).accountId}`,
                    method: 'GET'
                }).then(res => {
                    if (res && res.data) {
                        setData(res.data)
                    }
                    setLoading(false)
                }).catch(err => console.log('error', err))
            } catch (e) {
                setLoading(false)
                console.log('error', e)
            }
        }
        GetDashboardCount()
    }, []);

    let { userCount = 0, groupCount = 0, policyCount = 0, roleCount = 0 } = data;

    return (
        <Box>
            <Paper>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={2} px={3}>
                    <Typography component="h6" fontWeight={'bold'}>
                        Access Management Dashboard
                    </Typography>
                </Box>
            </Paper>
            <Container>
                {!loading ?
                    <Box p={3}>
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Paper elevation={1}>
                                    <Box p={2} display={'flex'} alignItems={'center'}>
                                        <PersonOutlineOutlinedIcon className={"dashIcons"} />
                                        <Box ml={3}>
                                            <Typography variant='subtitle1'>Visitor's Count</Typography>
                                            <Typography variant='h6'>{userCount}</Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item md={4}>
                                <Paper elevation={1}>
                                    <Box p={2} display={'flex'} alignItems={'center'}>
                                        <GroupOutlinedIcon className={"dashIcons"} />
                                        <Box ml={3}>
                                            <Typography variant='subtitle1'>No. of entries</Typography>
                                            <Typography variant='h6'>{groupCount}</Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>

                            {/* <Grid item md={3}>
                                <Paper elevation={1}>
                                    <Box p={2} display={'flex'} alignItems={'center'}>
                                        <EngineeringOutlinedIcon className={"dashIcons"} />
                                        <Box ml={3}>
                                            <Typography variant='subtitle1'>Roles</Typography>
                                            <Typography variant='h6'>{roleCount}</Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid item md={3}>
                                <Paper elevation={1}>
                                    <Box p={2} display={'flex'} alignItems={'center'}>
                                        <LocalPoliceOutlinedIcon className={"dashIcons"} />
                                        <Box ml={3}>
                                            <Typography variant='subtitle1'>Policies</Typography>
                                            <Typography variant='h6'>{policyCount}</Typography>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid> */}
                        </Grid>

                    </Box>
                    : <>
                        {/* <CustomLoader /> */}
                    </>}
            </Container>
        </Box>
    )
}
// const useStyles = makeStyles((theme) => ({
//     dashIcons: {
//         border: '1px solid #ccc',
//         width: '45px',
//         height: '45px',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         padding: 5,
//         borderRadius: 3
//     }
//     // content: {
//     //     flexGrow: 1,
//     //     padding: theme.spacing(3),
//     //     "& .loader": {
//     //         marginTop: '100px !important'
//     //     }
//     // },
//     // toolbar: {
//     //     display: "flex",
//     //     alignItems: "center",
//     //     marginTop: theme.spacing(1),
//     //     justifyContent: "flex-end",
//     //     padding: "0 8px",
//     //     ...theme.mixins.toolbar
//     // },
//     // button: {
//     //     margin: '10px 10px !important',
//     //     "& a": {
//     //         textDecoration: 'none'
//     //     }
//     // },
//     // card: {
//     //     display: 'flex',
//     //     justifyContent: 'space-evenly',
//     //     marginBottom: '10px',
//     //     marginTop: '50px',
//     //     " & .subcard": {
//     //         display: "flex",
//     //         flexDirection: "column",
//     //         alignItems: "center",
//     //         background: " #ffffff",
//     //         boxShadow: " 2px 10px 20px rgb(0 0 0 / 10%)",
//     //         borderRadius: "7px",
//     //         textAlign: "center",
//     //         position: "relative",
//     //         overflow: "hidden",
//     //         padding: " 0px 25px 20px",
//     //         height: "100%",
//     //         width: '300px',
//     //         '&:hover': {
//     //             boxShadow: " 2px 10px 20px rgb(0 0 0 / 58%)",
//     //         },
//     //         "& h1": {
//     //             color: " #6c6c6c",
//     //             fontSize: "1.18em",
//     //         },
//     //         "& span": {
//     //             fontWeight: "600",
//     //             fontSize: " 2.5em",
//     //             lineHeight: "64px",
//     //             color: " #3f51b5",
//     //         }
//     //     }
//     // }
// }));
