import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // light: '#33747b',
      // main: '#00525a',
      // dark: '#00393e',
      // contrastText: '#fff',
      main: '#cda778',
    },
    secondary: {
      main: '#17939b',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    p: {
      fontSize: '1rem',
      color: '#757575',
      lineHeight: 2
    },
  },
});

export default theme;