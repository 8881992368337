import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import loginBannerImage from "./../../assets/images/HomeImg.jpg";
import Logo from "./../../assets/images/Ezrece.jpg";
import { Box, Grid, Paper, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function ForgotPassword() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      // Add logic to handle forgot password
      // Display a toast indicating the feature is coming soon
      toast.info("Forgot Password functionality coming soon!");
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    }

    return errors;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: `url(${loginBannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container component="main">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={6} lg={4}>
            <Paper
              className="forgot-password"
              sx={{
                padding: 3,
              }}
            >
              <Box mb={3}>
                <Box mb={2}>
                  <img src={Logo} alt="" style={{ height: "40px" }} />
                </Box>
                <Typography component="h1" variant="h5" color={"#a1a1a1"}>
                  Forgot Password
                </Typography>
                <Typography component="body1" color={"#a1a1a1"}>
                  Enter your email to reset your password
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  fullWidth
                  margin="normal"
                />
                <Box my={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    Reset Password
                  </Button>
                </Box>
                <Box textAlign={"center"}>
                  <Typography component="body1" color={"#a1a1a1"}>
                    Remember your password?{" "}
                    <Link
                      component="button"
                      onClick={() => navigate("/Login1")}
                    >
                      Sign In
                    </Link>
                  </Typography>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
