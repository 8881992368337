import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import axios from "axios";
import { styled, Box, Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import CustomDialog from "../../Common/CustomDialog";
import AddUser from "./AddUser";
import { URLCONFIG } from "../../services/URLCONFIG";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "black",
  color: "white",
  fontWeight: "bold",
}));

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [activeConfirmationOpen, setActiveConfirmationOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accountId = sessionStorage.getItem("accountId");

        if (!accountId) {
          console.error("Account ID not found in session storage");
          return;
        }

        const response = await axios.get(
          `${URLCONFIG.ConfigBaseURL}api/users?accountId=${accountId}`
        );

        if (response.data && Array.isArray(response.data.content)) {
          setUsers(response.data.content);
        } else {
          console.error("Invalid API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.userId.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.accountName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActiveClick = (user) => {
    setSelectedUser(user);
    setActiveConfirmationOpen(true);
  };

  const handleActiveConfirmation = async () => {
    try {
      const updateResponse = await axios.post(
        `${URLCONFIG.ConfigBaseURL}api/users/status`,
        {
          activeYN: selectedUser.activeYN === 1 ? 0 : 1,
          userId: selectedUser.userId,
        }
      );

      console.log("Update Status API Response:", updateResponse.data);

      window.location.reload();
      // }
    } catch (error) {
      console.error("Error updating user status:", error);
    } finally {
      setActiveConfirmationOpen(false);
    }
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setDeleteConfirmationOpen(true);
  };

  const handleEditConfirmation = () => {
    console.log("Edit confirmed for user:", selectedUser);
    setEditConfirmationOpen(false);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const deleteResponse = await axios.delete(
        `${URLCONFIG.ConfigBaseURL}api/users?userId=${selectedUser.userId}`
      );

      console.log("Delete API Response:", deleteResponse.data);
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setDeleteConfirmationOpen(false);
    }
  };

  const renderFooterButtonsEdit = () => {
    return (
      <>
        <Button onClick={() => setEditConfirmationOpen(false)} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleEditConfirmation}
          color="primary"
          variant="contained"
        >
          Edit
        </Button>
      </>
    );
  };

  const renderFooterButtonsDelete = () => {
    return (
      <>
        <Button
          onClick={() => setDeleteConfirmationOpen(false)}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteConfirmation}
          color="primary"
          variant="contained"
          style={{ backgroundColor: "#FF3D00", color: "#FFFFFF" }}
        >
          Delete
        </Button>
      </>
    );
  };

  const renderFooterButtonsActive = () => {
    return (
      <>
        <Button
          onClick={() => setActiveConfirmationOpen(false)}
          color="primary"
        >
          No
        </Button>
        <Button
          onClick={handleActiveConfirmation}
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
      </>
    );
  };

  return (
    <>
      <Box>
        <Paper>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            py={2}
            px={3}
          >
            <Typography component="h6" fontWeight={"bold"}>
              Users Table
            </Typography>
            <Box>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
              >
                Add User
              </Button>
            </Box>
          </Box>
        </Paper>
        <Box alignItems={"center"} justifyContent={"center"} py={3}>
          <Container>
            <Box mb={3} display={"flex"} justifyContent={"flex-end"}>
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                size="small"
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                style={{ width: "320px" }}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email ID</StyledTableCell>
                    <StyledTableCell>Phone No</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Created Date</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user.userId}>
                      <TableCell>{user.accountName}</TableCell>
                      <TableCell>{user.emailId}</TableCell>
                      <TableCell>{user.phoneNo}</TableCell>
                      <TableCell>
                        <Badge
                          color={user.activeYN === 1 ? "success" : "error"}
                          badgeContent={
                            user.activeYN === 1 ? "Active" : "Inactive"
                          }
                          onClick={() => handleActiveClick(user)}
                        />
                      </TableCell>
                      <TableCell>{formatDate(user.createdDate)}</TableCell>
                      <TableCell>
                        <Button
                          // onClick={() => handleEditClick(user)}
                          variant="contained"
                          color="primary"
                          style={{ marginRight: "8px" }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => handleDeleteClick(user)}
                          variant="contained"
                          color="secondary"
                          style={{
                            backgroundColor: "#FF3D00",
                            color: "#FFFFFF",
                          }}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </Box>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title={"Add User"}
        renderContent={<AddUser />}
        renderFooterButtons={renderFooterButtonsEdit}
      />

      <CustomDialog
        open={editConfirmationOpen}
        handleClose={() => setEditConfirmationOpen(false)}
        title={"Edit User"}
        renderContent={<p>Are you sure you want to edit this user?</p>}
        renderFooterButtons={renderFooterButtonsEdit}
      />

      <CustomDialog
        open={deleteConfirmationOpen}
        handleClose={() => setDeleteConfirmationOpen(false)}
        title={"Delete User"}
        renderContent={<p>Are you sure you want to delete this user?</p>}
        renderFooterButtons={renderFooterButtonsDelete}
      />

      <CustomDialog
        open={activeConfirmationOpen}
        handleClose={() => setActiveConfirmationOpen(false)}
        title={
          selectedUser && selectedUser.activeYN === 1
            ? "Deactivate User"
            : "Activate User"
        }
        renderContent={
          <p>
            Are you sure you want to{" "}
            {selectedUser && selectedUser.activeYN === 1
              ? "deactivate"
              : "activate"}{" "}
            this user?
          </p>
        }
        renderFooterButtons={renderFooterButtonsActive}
      />
    </>
  );
};

export default UserTable;
