import React from 'react'
import { Box, Typography, Link } from '@mui/material';

const Copyright = (props) => {
    return (
        <Box sx={{ mt: 5 }}>
            <Typography variant="body2" color="text.secondary" align="center" {...props}>
                {'Copyright © '}
                <Link color="inherit" href="http://acsproject.s3-website-us-west-2.amazonaws.com/">
                    ACS
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}{'   '}
                {!props.hideOTP &&
                    <Link color="inherit" href="/otp">
                        Verify Otp
                    </Link>
                }
            </Typography>
        </Box>
    )
}

export default Copyright
