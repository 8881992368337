import Team from "./../../assets/images/team.jpg"
import History from "./../../assets/images/history.jpg"
import Mission from "./../../assets/images/mission.jpg"


export const AboutUs = [
    {
        ourTeam: [
            {
                Image: Team,
                Heading: "Our Team",
                Content: "TIET is an registered NGO backed by passionate professionals of Pernambut. Joined hands to take their motherland to greater  heights. Our amazing team of regulars and part-time volunteers are committed to helping others. Think, you would be a good fit? Get in touch for more information!"
            }
        ],
        ourHistory: [
            {
                Image: History,
                Heading: "Our History",
                Content: "Since its inception in 2011, TIET has successfully completed number of projects ranging from academic coaching, Skill Training, Computer Training, spoken English programs, workshops, seminars, fully funded-scholarships to the needy, career placements etc"
            }
        ],
        ourMission: [
            {
                Image: Mission,
                Heading: "Our Mission",
                Content: "TIET is committed to assist the Unemployed Youths/students  through appropriate guidance for attaining quality education, skill development and career building. TIET’s vision is to harness the inbuilt talent of young Unemployed Youths/Students, motivate them to think and dream big so as to achieve higher goals."
            }
        ]
    }
];