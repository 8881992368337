import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Avatar,
  useScrollTrigger,
  Button,
  Typography,
} from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "./../../assets/images/Ezrece.jpg";
import { NavItems } from "../../assets/json/NavItems";
import LogoutIcon from "@mui/icons-material/Logout";
import "./style.scss";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

// const drawerWidth = 330;

export default function AppHeader(props) {
  let location = useLocation();
  const navigate = useNavigate();
  const { window } = props;
  const [drawerWidth, setDrawerWidth] = useState(80);

  const handleDrawerWidth = (val) => {
    setDrawerWidth(val);
  };


  const activeSubMenu = NavItems.map(
    (item) =>
      item &&
      item.children &&
      item.children
        .map((val) => val.path)
        .find((val) => val === location.pathname)
  );
  const checkActiveSubmenu = activeSubMenu
    .map((val) => val !== undefined)
    .filter((e) => e === true);

  useEffect(
    () => (checkActiveSubmenu[0] ? setDrawerWidth(330) : setDrawerWidth(80)),
    [checkActiveSubmenu]
  );

  const menuList = (
    <List className="navMenu" sx={{ height: "100%" }}>
      {NavItems.map((nav, key) =>
        Object.keys(nav).some((val) => Array.isArray(nav[val])) ? (
          <ListItem
            key={key}
            className={
              nav.path === location.pathname ||
                nav.children
                  .map((val) => val.path)
                  .find((val) => val === location.pathname)
                ? "active"
                : "noactive"
            }
          >
            <ListItemButton
              component={Link}
              to={nav.path}
              onClick={() => handleDrawerWidth(330)}
            >
              <span>{nav.icon}</span>
              {nav.pageName}
              {/* <ExpandMoreIcon /> */}
            </ListItemButton>
            <List
              className="subNavMenu"
              sx={{
                width: `calc(${drawerWidth}px - 80px)`,
                top: "48px",
                height: "calc(100% - 48px)",
              }}
            >
              {nav.children.map((nav, key) => (
                <>
                  <ListItem key={key}>
                    <ListItemButton
                      component={Link}
                      to={nav.path}
                      onClick={() => handleDrawerWidth(330)}
                      className={
                        nav.path === location.pathname ? "active" : "noactive"
                      }
                      target={nav.download ? "_blank" : ""}
                    >
                      <span style={{ marginRight: 10 }}>{nav.icon}</span>
                      {nav.pageName}
                    </ListItemButton>
                  </ListItem>
                </>
              ))}
            </List>
          </ListItem>
        ) : (
          <ListItem
            key={key}
            className={nav.path === location.pathname ? "active" : "noactive"}
          >
            <ListItemButton
              component={Link}
              to={nav.path}
              target={nav.download ? "_blank" : ""}
              onClick={() => handleDrawerWidth(80)}
            >
              <span>{nav.icon}</span>
              {nav.pageName}
            </ListItemButton>
          </ListItem>
        )
      )}
    </List>
  );

  const drawer = <>{menuList}</>;

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handlelogout = async (e) => {
    e.preventDefault();
    sessionStorage.clear()
    navigate("/Login1")
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          component="nav"
          color="default"
          sx={{ backgroundColor: "#000" }}
        >
          <Toolbar variant="dense">
            <Avatar
              component={Link}
              to={"/"}
              alt="Logo"
              src={Logo}
              sx={{ height: 40, width: "auto", borderRadius: 0 }}
            />

            <Box
              sx={{ display: { xs: "none", md: "block" }, marginLeft: "auto" }}
            >
              {/* {menuList} */}
              {/* <Button component={Link} to={"/Login1"} sx={{ color: "#fff" }}>
                <LogoutIcon />
                Sign In
              </Button>
              <Button
                component={Link}
                to={"/subscription"}
                target="_blank"
                sx={{ color: "#fff" }}
              >
                Subscription
              </Button> */}
              <Typography component="body1" color={"#a1a1a1"}>
                  Welcome, {sessionStorage.getItem("accountName")}
                </Typography>
              <Button onClick={handlelogout} sx={{ color: "#fff" }}>
                <LogoutIcon />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {/* <Toolbar /> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label=""
        position="static"
      >
        <Drawer
          variant="permanent"
          open
          sx={{
            // display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              top: "48px",
              height: "calc(100% - 48px)",
              overflow: "hidden",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {props.pagesNav}
    </Box>
  );
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
