import DashboardIcon from "@mui/icons-material/Dashboard";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EngineeringIcon from "@mui/icons-material/Engineering";

export const NavItems = [
  {
    path: "/dashboard",
    pageName: "Dashboard",
    icon: <DashboardIcon />,
  },
  {
    path: "/visitorentry",
    pageName: "Forms",
    icon: <PeopleOutlinedIcon />,
    children: [
      {
        path: "/visitorentry",
        pageName: "Visitor Entry",
        icon: <PeopleOutlinedIcon />,
      },
      {
        path: "/report",
        pageName: "Reports",
        icon: <EditNoteOutlinedIcon />,
      },
      // {
      //   path: "/myForms",
      //   pageName: "My Forms",
      //   icon: <PeopleOutlinedIcon />,
      // },
      // {
      //   path: "/sharedForms",
      //   pageName: "Shared Forms",
      //   icon: <EditNoteOutlinedIcon />,
      // },
    ],
  },
  {
    path: "/userList",
    pageName: "Users",
    icon: <PeopleOutlinedIcon />,
    // children: [
    //   {
    //     path: "/userList",
    //     pageName: "User List",
    //     icon: <PeopleOutlinedIcon />,
    //   },
    //   {
    //     path: "/addEdituser",
    //     pageName: "Add / Edit user",
    //     icon: <EditNoteOutlinedIcon />,
    //   },
    // ],
  },
  // {
  //   path: "/rolesList",
  //   pageName: "Roles",
  //   icon: <ManageAccountsIcon />,
  //   children: [
  //     {
  //       path: "/rolesList",
  //       pageName: "Roles List",
  //       icon: <ManageAccountsIcon />,
  //     },
  //     {
  //       path: "/addEditRoles",
  //       pageName: "Add / Edit Roles",
  //       icon: <EngineeringIcon />,
  //     },
  //   ],
  // }
  {
    path: "/changePassword",
    pageName: "Change Password",
    icon: <ManageAccountsIcon />,
  },
];
