import React, { useState } from "react";
import { TextField, Button, Paper, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { URLCONFIG } from "../../services/URLCONFIG";

const StyledPaper = styled(Paper)({
  maxWidth: 400,
  padding: 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#f5f5f5",
  borderRadius: "8px",
  margin: "auto",
  marginTop: "50px",
});

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");

  const handleFormChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validation: Check if new password and confirm password match
    if (formData.newPassword !== formData.confirmPassword) {
      setError("New Password and Confirm Password do not match");
      return;
    }

    try {
      // Get email from sessionStorage
      const email = sessionStorage.getItem("emailId");

      // Check if email is available
      if (!email) {
        setError("Email not found. Please login again.");
        return;
      }

      // Prepare data for the API call
      const requestData = {
        emailId: email,
        password: formData.currentPassword,
        newPassword: formData.newPassword,
      };

      const apiUrl = `${URLCONFIG.ConfigBaseURL}api/changePassword`;
      const response = await axios.post(apiUrl, requestData);

      // Handle API response as needed
      console.log("Change Password API Response:", response.data);

      // Clear form fields on successful submission
      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setError(""); // Clear any previous error
    } catch (error) {
      // Handle error from API call
      console.error("Error changing password:", error);
      setError("Error changing password. Please try again.");
    }
  };

  return (
    <Box>
      <StyledPaper elevation={3}>
        <LockIcon style={{ fontSize: 50, color: "#3f51b5", marginBottom: 2 }} />
        <Typography variant="h6" color="primary">
          Change Password
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Current Password"
            variant="outlined"
            fullWidth
            type="password"
            margin="normal"
            size="small"
            required
            value={formData.currentPassword}
            onChange={handleFormChange("currentPassword")}
          />
          <TextField
            label="New Password"
            variant="outlined"
            fullWidth
            type="password"
            margin="normal"
            size="small"
            required
            value={formData.newPassword}
            onChange={handleFormChange("newPassword")}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            type="password"
            margin="normal"
            size="small"
            required
            value={formData.confirmPassword}
            onChange={handleFormChange("confirmPassword")}
          />
          {error && (
            <Typography variant="body2" color="error" gutterBottom>
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: 16 }}
          >
            Update
          </Button>
        </form>
      </StyledPaper>
    </Box>
  );
};

export default ChangePassword;
