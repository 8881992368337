import { Box, Paper, Typography, Container, Grid, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const AddEditForms = () => {
    const [formData, setFormData] = useState([{
        formTitle: "",
    }]);

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);

        if (Object.keys(validationErrors).length === 0) {
            try {
                
                // Handle the response as needed
                console.log("formTitle Successful. Response:", formData);
                localStorage.setItem("formData", JSON.stringify(formData))

                // Optionally, you can redirect the user or perform other actions based on the response.
            } catch (error) {
                // Handle API call errors
                console.error("Login Error:", error.formData);

                // Update state or show an error message to the user.
                setErrors({ formTitle: "Invalid formTitle or password" });
            }
        } else {
            setErrors(validationErrors);
        }
    };

    const validateForm = (data) => {
        let errors = {};

        if (!data.formTitle) {
            errors.formTitle = "formTitle is required";
        }

        return errors;
    };

    return (
        <Box>
            <Paper>
                <Box textAlign={'center'} py={2} px={3}>
                    <Typography component="h6" fontWeight={'bold'}>
                        Create From Scratch
                    </Typography>
                </Box>
            </Paper>

            <Box m={3}>
                <Container>
                    <Grid container justifyContent={'center'}>
                        <Grid item md={8}>
                            <Paper>
                                <Box p={3}>
                                    <Typography component="h6" fontWeight={'bold'} textAlign={'center'} gutterBottom>
                                        Form Type
                                    </Typography>
                                    <Box p={3} width={'240px'} mx={'auto'} display={'flex'} flexDirection={'column'} alignItems={'center'} my={2} sx={(theme) => ({
                                        border: '1px solid ', borderColor: 'primary.light', borderRadius: 1, position: 'relative'
                                    })}>
                                        <CheckCircleOutlineIcon color='primary' sx={{position: 'absolute', top: 10, right: 10}} />
                                        <Typography variant="h6" textAlign={'center'} color={'primary'}>
                                            Standard
                                        </Typography>
                                        <Typography variant="caption" textAlign={'center'} color={'primary'}>
                                            Show multiple fields on a page
                                        </Typography>
                                    </Box>

                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            label="Form Title"
                                            name="formTitle"
                                            value={formData.formTitle}
                                            onChange={handleChange}
                                            error={errors.formTitle ? true : false}
                                            helperText={errors.formTitle}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <Box my={3} textAlign={'center'}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                                sx={{mx: .25}}
                                            >
                                                Cancel
                                            </Button>
                                            
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                                sx={{mx: .25}}
                                            >
                                                Create
                                            </Button>
                                        </Box>
                                    </form>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    )
}

export default AddEditForms