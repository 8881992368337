import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import { Box, Container } from "@mui/system";
import { Autocomplete, Paper, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { URLCONFIG } from "../../services/URLCONFIG";

const WebcamBox = ({ onCapture, onRetake }) => {
  const videoRef = useRef(null);
  const [isWebcamActive, setWebcamActive] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  const captureImage = () => {
    const video = videoRef.current;

    if (!isWebcamActive) {
      initializeWebcam();
      setWebcamActive(true);
    }

    if (video && video.videoWidth) {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageSrc = canvas.toDataURL("image/jpeg");
      setCapturedImage(imageSrc);
      onCapture(imageSrc); // Callback to the parent component to handle image capture
    } else {
      console.error("Video or its dimensions are not available yet.");
    }
  };

  const handleRetake = () => {
    setCapturedImage(null);
    initializeWebcam();
    setWebcamActive(true);
    onRetake(); // Callback to the parent component to handle retake logic
  };

  const stopWebcam = () => {
    const stream = videoRef.current.srcObject;
    if (stream instanceof MediaStream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setWebcamActive(false);
  };

  const initializeWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  return (
    <Paper>
      <Box
        p={3}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        textAlign="center"
      >
        {!capturedImage && !isWebcamActive ? (
          <>
            <Avatar
              onClick={captureImage}
              sx={{
                width: "100px",
                height: "100px",
                cursor: "pointer",
                marginBottom: "10px",
                backgroundColor: "#cda778",
                borderRadius: "50%", // Circular style
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <PhotoCameraIcon
                style={{ width: "50px", height: "50px", color: "white" }}
              /> */}
              <AccountCircleIcon
                style={{ width: "100px", height: "100px", color: "white" }}
              />
            </Avatar>
            <Typography variant="caption">Click to capture photo</Typography>
          </>
        ) : null}
        {!capturedImage && isWebcamActive && (
          <>
            <video
              ref={videoRef}
              autoPlay
              style={{ maxWidth: "100%", maxHeight: "300px" }}
            />
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={captureImage}
              >
                Capture Image
              </Button>
            </Box>
          </>
        )}
        {capturedImage && (
          <Box mt={2}>
            <Avatar
              src={capturedImage}
              alt="Captured"
              style={{
                width: "240px",
                height: "240px",
                borderRadius: "50%", // Circular style
              }}
            />
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRetake}
              >
                Retake
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

const FormBox = ({ formData, onChange, onSubmit, setFormData }) => {
  const [phoneNoList, setPhoneNoList] = useState([]);
  const [responseVal, setResponseVal] = useState([]);

  const handleAutoCompleteClose = (val) => {
    let dataVal = responseVal
      .filter((order) => order.phoneNo == val)
      .map((order) => order);
    setFormData({
      name: dataVal[0].visitorName,
      // phoneNumber: dataVal[0].phoneNumber,
      email: dataVal[0].emailId,
      location: dataVal[0].location,
      //whomToMeet: dataVal[0].meetWith,
      // reason: dataVal[0].reason,
    });
  };

  const handlePhoneNumberKeyDown = async (event) => {
    const { value } = event.target;
    const accountId = sessionStorage.getItem("accountId");

    // alert(accountId);

    // Check if the entered phone number is greater than 5 digits
    if (value.length > 5) {
      try {
        const apiUrl = `${URLCONFIG.ConfigBaseURL}api/visitor/search`;
        const response = await axios.post(apiUrl, {
          accountId,
          phoneNo: value,
        });

        setResponseVal(response.data);

        setPhoneNoList(response.data.map((val) => val.phoneNo));

        console.log("Phone number search API Response:", response);
      } catch (error) {
        console.error("Error searching for phone number:", error);
      }
    }
  };

  return (
    <Paper>
      <Box p={3}>
        <form onSubmit={onSubmit}>
          {/* <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            value={formData.phoneNumber}
            margin="normal"
            size="small"
            onChange={onChange("phoneNumber")}
            onKeyDown={handlePhoneNumberKeyDown}
          /> */}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={phoneNoList}
            onKeyDown={handlePhoneNumberKeyDown}
            onChange={(e, newValue) => handleAutoCompleteClose(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Phone Number"
                size="small"
                margin="normal"
              />
            )}
          />

          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={formData.name}
            margin="normal"
            size="small"
            onChange={onChange("name")}
          />

          <TextField
            label="Email Id"
            variant="outlined"
            fullWidth
            value={formData.email}
            margin="normal"
            size="small"
            onChange={onChange("email")}
          />

          <TextField
            label="Location"
            variant="outlined"
            fullWidth
            value={formData.location}
            margin="normal"
            size="small"
            onChange={onChange("location")}
          />

          <TextField
            label="Whom to Meet"
            variant="outlined"
            fullWidth
            value={formData.meetWith}
            margin="normal"
            size="small"
            onChange={onChange("whomToMeet")}
          />

          <TextField
            label="Reason"
            variant="outlined"
            fullWidth
            value={formData.reason}
            margin="normal"
            size="small"
            onChange={onChange("reason")}
          />

          <Box mt={3} textAlign={"center"}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

const VisitorEntry = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    location: "",
    whomToMeet: "",
    reason: "",
  });

  const [capturedImage, setCapturedImage] = useState(null);
  const handleFormChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  // const handleFormSubmit = (event) => {
  //   event.preventDefault();

  //   const apiUrl = `${URLCONFIG.ConfigBaseURL}api/entry`;
  //   const fixedAccountId = sessionStorage.getItem("accountId");

  //   const requestData = {
  //     accountId: fixedAccountId,
  //     visitorName: formData.name,
  //     phoneNo: formData.phoneNumber,
  //     emailId: formData.email,
  //     reason: formData.reason,
  //     location: formData.location,
  //     meetingWith: formData.meeingtWith,
  //     photo: capturedImage,
  //   };

  //   axios
  //     .post(apiUrl, requestData)
  //     .then((response) => {
  //       console.log("API Response:", response.data);

  //       // Clear form fields and captured image on successful submission
  //       setFormData({
  //         name: "",
  //         phoneNumber: "",
  //         email: "",
  //         location: "",
  //         meetingWith: "",
  //         reason: "",
  //       });
  //       setCapturedImage(null);
  //     })
  //     .catch((error) => {
  //       console.error("Error submitting form:", error);
  //     });
  // };
  const handleFormSubmit = (event) => {
    event.preventDefault();

    const apiUrl = `${URLCONFIG.ConfigBaseURL}api/entry`;
    const fixedAccountId = sessionStorage.getItem("accountId");

    const requestData = {
      accountId: fixedAccountId,
      visitorName: formData.name,
      phoneNo: formData.phoneNo,
      emailId: formData.email,
      reason: formData.reason,
      location: formData.location,
      meetingWith: formData.whomToMeet, // Corrected parameter name
      photo: capturedImage,
    };

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        console.log("API Response:", response.data);

        // Clear form fields and captured image on successful submission
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          location: "",
          whomToMeet: "", // Corrected field name
          reason: "",
        });
        setCapturedImage(null);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleWebcamCapture = (image) => {
    setCapturedImage(image);
  };

  const handleWebcamRetake = () => {
    setCapturedImage(null);
  };

  return (
    <Box>
      <Paper>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={2}
          px={3}
        >
          <Typography component="h6" fontWeight={"bold"}>
            Visitor's Entry
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <CalendarMonthIcon color={"primary"} />
            <Typography
              variant="caption"
              color={"primary"}
              fontWeight={"bold"}
              ml={1}
            >
              {new Date().getDate()} - {new Date().getMonth() + 1} -{" "}
              {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Box py={3}>
        <Container>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <WebcamBox
                onCapture={handleWebcamCapture}
                onRetake={handleWebcamRetake}
              />
            </Grid>
            <Grid item md={8}>
              <FormBox
                formData={formData}
                onChange={handleFormChange}
                onSubmit={handleFormSubmit}
                setFormData={setFormData}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default VisitorEntry;
