import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const theme = createTheme({
  palette: {
    primary: {
      main: '#cda778',
    },
    secondary: {
      main: '#17939b',
    },
    background: {
      paper: "rgba(0,0,0,.75)"
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '--TextField-brandBorderColor': '#a1a1a1',
          '--TextField-brandBorderHoverColor': '#a1a1a1',
          '--TextField-brandBorderFocusedColor': '#cda778',
          "& input": {
            color: 'var(--TextField-brandBorderColor)',
          },
          '& label': {
            color: 'var(--TextField-brandBorderColor)',
            '&.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            }
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'var(--TextField-brandBorderColor)',
          color: 'var(--TextField-brandBorderFocusedColor)',
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderHoverColor)',
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:before, &:after': {
            borderBottom: '2px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:before': {
            borderBottom: '2px solid var(--TextField-brandBorderColor)',
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    p: {
      fontSize: '1rem',
      color: '#757575',
      lineHeight: 2
    },
  },
});

export default theme;