import React from 'react'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const PricingTable = (props) => {
    const pricingTable = [
        {
            plan: 'Basic',
            amount: {
                year: '6960',
                month: '700'
            },
            planDetails: [
                "1 User",
                "Unlimited Forms",
                "10000 Submissions/month",
                "Unlimited Reports",
                "Payment Integration (Paypal, Stripe, 2Checkout, Razorpay)",
                "500 MB Storage",
                "Built-in Themes + Custom Themes"
            ]
        },
        {
            plan: 'Standard',
            amount: {
                year: '17400',
                month: '1750'
            },
            planDetails: [
                "10 Users",
                "Unlimited Forms",
                "25000 Submissions/month",
                "Unlimited Reports",
                "Payment Integration (Paypal, Stripe, 2Checkout, Razorpay)",
                "2 GB Storage",
                "Built-in Themes + Custom Themes",
                "Approvals",
                "Tasks"
            ]
        }
    ]
    return (
        <Box>
            <Grid container spacing={2} justifyContent={'center'}>
                {pricingTable.map((val, key) => (
                    <Grid key={key} item md={3}>
                        <Paper>
                            <Box>
                                <Box p={3}>
                                    <Typography variant="h5" color={'primary'} textAlign={'center'} gutterBottom>
                                        {val.plan}
                                    </Typography>
                                    <Typography variant="h6" color={'primary'} justifyContent={'center'} display={'flex'} alignItems={'center'} mb={3}>
                                        <CurrencyRupeeIcon /> {val.amount.month}
                                    </Typography>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={props.handlePricing}
                                    >
                                        Upgrade
                                    </Button>
                                </Box>
                                <List dense={true}>
                                    {val.planDetails.map((text, i) => (
                                        <>
                                            <Divider />
                                            <ListItem key={i}>
                                                <ListItemIcon>
                                                    <CheckCircleOutlineIcon color='primary' />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={text}
                                                // secondary={secondary ? 'Secondary text' : null}
                                                />
                                            </ListItem>
                                        </>
                                    ))}

                                </List>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default PricingTable