import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import FundRaise from "./../../assets/images/donate/fundraise.jpg";

import Banner from "../../constants/Banner/Banner";

const Pricings = () => {
  return (
    <>
      <Banner
        Image={FundRaise}
        BannerHeading={"Donate Us"}
        IsHomeBanner={false}
      ></Banner>
      <Box className={"DonateUs"} p={8}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            fontWeight={"bold"}
            gutterBottom
          >
            Your Suppport is Crucial in ensuring education for all !
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Typography variant="p">
                After a gap of two years, schools have reopened. Watching
                children go back to school, meet their friends, share lunch and
                play together has been a moment to rejoice. But this is just one
                side of the fence. The pandemic affected every child, but some
                suffered more than others. Children from socio-economically
                weaker sections, tribal belts, remote rural areas and urban
                slums, became the worst sufferers. When the schools were shut,
                only about 28% of children living in rural areas and slums had
                access to digital learning. Education came to a halt for lakhs
                of children, who had already been struggling in the pre-pandemic
                world. Smile Foundation, through its ‘Shiksha Na Ruke’
                initiative, has been helping children from difficult
                circumstances get back to school and restart their lives
                positively, and with hope. At present, we are directly providing
                education to over 100,000 children in 23 states of India.
                Despite all the odds, these young champions have not given up
                and continue to dream and work hard. With your support, we can
                fuel their dreams with accessible and quality education. Join
                hands with us to ensure a happy and safe childhood for all!
              </Typography>
            </Grid>
            <Grid item md={6}>
              <iframe
                src="https://pages.razorpay.com/pl_GHwsKhyZZZB7Mg/view"
                title="description"
                width={"100%"}
                height={"100%"}
                style={{ overflow: "hidden", minHeight: "380px" }}
              ></iframe>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Box>
        <img src={Fundraise1} alt="01" width={"100%"} />
      </Box>
      <Box>
        <img src={Fundraise2} alt="02" width={"100%"} />
      </Box> */}
    </>
  );
};

export default Pricings;
