import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import loginBannerImage from "./../../assets/images/HomeImg.jpg";
import Logo from "./../../assets/images/Ezrece.jpg";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { URLCONFIG } from "../../services/URLCONFIG";
import { ToastContainer, toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(
          `${URLCONFIG.ConfigBaseURL}api/auth/login`,
          {
            emailId: formData.email,
            password: formData.password,
          }
        );

        if (response.data.lstobj) {
          sessionStorage.setItem(
            "accountId",
            response?.data?.lstobj?.accountId
          );
          sessionStorage.setItem("token", response?.data.token);
          sessionStorage.setItem(
            "accountName",
            response?.data?.lstobj?.accountName
          );
          sessionStorage.setItem("emailId", formData.email); // Save email ID

          navigate("/dashboard");
        } else {
          toast.error(response.data.message);
        }

        console.log("Login Successful. Response:", response);
      } catch (error) {
        console.error("Login Error:", error.response.data);
        setErrors({ login: "Invalid email or password" });
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    }

    if (!data.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  const handleForgotPassword = () => {
    // Add logic to handle forgot password
    // toast.info("Forgot Password functionality coming soon!");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: `url(${loginBannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container component="main">
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={12} md={5} lg={4}>
            <Paper
              className="login"
              sx={{
                padding: 3,
              }}
            >
              <Box mb={3}>
                <Box mb={2}>
                  <img src={Logo} alt="" style={{ height: "40px" }} />
                </Box>
                <Typography component="h1" variant="h5" color={"#a1a1a1"}>
                  Sign in
                </Typography>
                <Typography component="body1" color={"#a1a1a1"}>
                  to access Forms
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  fullWidth
                  margin="normal"
                />
                <br />
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  fullWidth
                  margin="normal"
                />
                <Box my={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    Sign In
                  </Button>
                </Box>
                <Link
                  href="#"
                  onClick={handleForgotPassword}
                  color="secondary"
                  style={{ textDecoration: "underline" }}
                >
                  Forgot Password?
                </Link>
                <Box textAlign={"center"}>
                  <Typography component="body1" color={"#a1a1a1"}>
                    Don't have an account?{" "}
                    <Button component={Link} to={"/SignUp"} color="primary">
                      Sign Up Now
                    </Button>
                  </Typography>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default Login;
