import { Paper, Typography, Button, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox, Hidden } from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const MyForms = () => {

    const [checked, setChecked] = React.useState([0]);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    // const formData = {}
    // useEffect(() => (
    //     formData = localStorage.getItem("formData")
    // ),[formData])
    const formData = localStorage.getItem("formData")
    console.log(formData, "formDataformData")

    return (
        <Box>
            <Paper>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} py={2} px={3}>
                    <Typography component="h6" fontWeight={'bold'}>
                        My Forms
                    </Typography>
                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={"/addEditForm"}
                            size="small"
                        >
                            New Form
                        </Button>
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                        <IconButton>
                            <FilterAltOutlinedIcon />
                        </IconButton>
                        <IconButton sx={{ transform: 'rotate(90deg)' }}>
                            <SyncAltOutlinedIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Paper>

            <Box m={3}>
                <List sx={{ width: '100%' }}>
                    {[0, 1, 2, 3].map((value) => {
                        const labelId = `checkbox-list-label-${value}`;

                        return (
                            <ListItem
                                key={value}
                                disablePadding
                                sx={{
                                    bgcolor: 'background.paper',
                                    mb: 2,
                                    borderLeft: '2px solid transparent',
                                    borderRadius: 1,
                                    "&:hover": {
                                        borderLeftColor: 'primary',
                                        bgcolor: 'background.paper',
                                    },
                                }}
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(value)} dense sx={{
                                    "&:hover": {
                                        bgcolor: 'background.paper',
                                        transition: '.5s',
                                        "& .hideHover": {
                                            opacity: 0,
                                            visibility: 'hidden',
                                            height: 0,
                                            width: 0,
                                            transition: '.5s',
                                        },
                                        "& .showHover": {
                                            opacity: 1,
                                            visibility: 'visible',
                                            height: 'auto',
                                            width: '100%',
                                            transition: '.5s',
                                        }
                                    }
                                }}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={checked.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <Box sx={{ border: '1px solid #ccc', borderRadius: 1, width: 45, height: 45, display: 'flex', alignItems: 'center', justifyContent: 'center', ml: -3, mr: 2 }}>
                                        <DescriptionOutlinedIcon color='primary' />
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} className={'showHover'} sx={{ opacity: 0, visibility: 'hidden', height: 0, width: 0, transition: '.5s', }}>
                                        <ListItemText id={labelId} primary={'test'} secondary={"Created on: Nov 12, 2023"} color='primary' sx={{whiteSpace: 'nowrap'}}/>
                                        <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                                            <IconButton edge="end" aria-label="edit" component={Link} to={"/addEditForm"}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} className={'hideHover'}>
                                        <ListItemText id={labelId} primary={'test'} secondary={"Created on: Nov 12, 2023"} />
                                    </Box>
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Box>
    )
}

export default MyForms