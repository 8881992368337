import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Avatar, Box, Paper, Typography } from "@mui/material";
import SignUpBg from "./../../assets/images/HomeImg.jpg";
import Logo from "./../../assets/images/Ezrece.jpg";
import AvatarImage from "./../../assets/images/Avatar.jpg";
import { Link } from "react-router-dom";
import { URLCONFIG } from "../../services/URLCONFIG";

function SignUp({ history }) {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);

    if (Object.keys(validationErrors).length === 0) {
      try {
        // Make API call using Axios
        const response = await axios.post(
          `${URLCONFIG.ConfigBaseURL}api/auth/signup`,
          {
            accountName: formData.username,
            emailId: formData.email,
            password: formData.password,
            phoneNumber: formData.phoneNumber,
          }
        );

        // Handle the response as needed
        console.log("API Response:", response.data);

        // Display success toast
        toast.success("Account created successfully!");

        // Reset the form fields
        setFormData({
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
          phoneNumber: "",
        });

        // Redirect the user to the login page after a short delay
        setTimeout(() => {}, 2000);
      } catch (error) {
        // Handle API call errors
        console.error("API Error:", error);

        // Display error toast
        toast.error("An error occurred while creating the account.");
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.username) {
      errors.username = "Username is required";
    }

    if (!data.email) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email format";
    }

    if (!data.password) {
      errors.password = "Password is required";
    }

    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Check if the email has a valid format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    // Custom domain validation: Check if the email contains a dot after '@'
    if (!emailRegex.test(email)) {
      return false;
    }

    return true;
  };

  return (
    <Box
      p={3}
      display={"flex"}
      minHeight={"100vh"}
      position={"relative"}
      zIndex={0}
      sx={[
        {
          background: `url(${SignUpBg})`,
          backgroundSize: "cover",
        },
        {
          "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            top: 0,
            left: 0,
            bottom: 0,
            background: "rgba(0,0,0,.35)",
            zIndex: -1,
          },
        },
      ]}
    >
      <Container my={"auto"}>
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Grid item md={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Avatar
                alt="Remy Sharp"
                src={AvatarImage}
                sx={{ width: 56, height: 56 }}
              />
              <Typography
                component={"h6"}
                color={"#f2f2f2"}
                mt={3}
                textAlign={"center"}
              >
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat."
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Paper
              className="signUp"
              sx={{ p: 3, background: "rgba(0,0,0,.75)" }}
            >
              <Box mb={3}>
                <Box mb={2}>
                  <img src={Logo} alt="" style={{ height: "40px" }} />
                </Box>
                <Typography component="h1" variant="h5" color={"#a1a1a1"}>
                  Sign Up to get started
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  error={errors.username ? true : false}
                  helperText={errors.username}
                  fullWidth
                  margin="normal"
                  size={"small"}
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  fullWidth
                  margin="normal"
                  size={"small"}
                />
                <TextField
                  label="Password"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  fullWidth
                  margin="normal"
                  size={"small"}
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  error={errors.confirmPassword ? true : false}
                  helperText={errors.confirmPassword}
                  fullWidth
                  margin="normal"
                  size={"small"}
                />
                <TextField
                  label="Phone Number"
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  error={errors.phoneNumber ? true : false}
                  helperText={errors.phoneNumber}
                  fullWidth
                  margin="normal"
                  size={"small"}
                />
                <Box my={3}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    Sign Up
                  </Button>
                </Box>
                <Box textAlign={"center"}>
                  <Typography component="body1" color={"#a1a1a1"}>
                    Don't have an account?{" "}
                    <Button component={Link} to={"/Login1"} color="primary">
                      Sign In
                    </Button>
                  </Typography>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <ToastContainer />
    </Box>
  );
}

export default SignUp;
