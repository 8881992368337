import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Divider,
  InputBase,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/system";
import axios from "axios";
import { URLCONFIG } from "../../services/URLCONFIG";
import * as XLSX from "xlsx";

const StyledTable = styled(TableContainer)({
  maxHeight: 400,
  marginBottom: 20,
});

const columns = [
  { id: "visitorName", label: "Visitor Name", minWidth: 170 },
  { id: "emailId", label: "Email Id", minWidth: 170 },
  {
    id: "phoneNo",
    label: "Mobile",
    minWidth: 170,
  },
  {
    id: "entryDate",
    label: "Entry Date",
    minWidth: 170,
    format: (value) =>
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(value)),
  },
  { id: "reason", label: "Reason", minWidth: 170 },
];

const GridTable = () => {
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    axios
      .get(
        `${URLCONFIG.ConfigBaseURL}api/entry/${sessionStorage.getItem(
          "accountId"
        )}`
      )
      .then((response) => {
        console.log("API Response:", response.data);
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [filter]);

  const handleSearch = () => {
    axios
      .get(
        `${URLCONFIG.ConfigBaseURL}api/entry/${localStorage.getItem(
          "accountId"
        )}`
      )
      .then((response) => {
        const filteredData = response.data.filter(
          (row) =>
            row["visitorName"]
              .toLowerCase()
              .includes(filter.search.toLowerCase()) ||
            row["emailId"].toLowerCase().includes(filter.search.toLowerCase())
        );
        setRows(filteredData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const exportToExcel = () => {
    // Extracting only the desired columns
    const filteredRows = rows.map((row) => ({
      visitorName: row.visitorName,
      emailId: row.emailId,
      phoneNo: row.phoneNo,
      reason: row.reason,
      entryDate: row.entryDate,
      // Add more fields if needed
    }));

    const ws = XLSX.utils.json_to_sheet(filteredRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "VisitorData");
    XLSX.writeFile(wb, "visitor_data.xlsx");
  };

  return (
    <Box>
      <Paper>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          py={2}
          px={3}
        >
          <Typography component="h6" fontWeight={"bold"}>
            Visitor Information
          </Typography>
          <Box>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by name or email"
                inputProps={{ "aria-label": "Search by name or email" }}
                value={filter["search"] || ""}
                onChange={(e) =>
                  setFilter({ ...filter, search: e.target.value })
                }
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
          <Button variant="contained" color="primary" onClick={exportToExcel}>
            Download Excel
          </Button>
        </Box>
      </Paper>
      <Box m={3}>
        <StyledTable component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.entryId}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.format
                        ? column.format(row[column.id])
                        : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTable>
      </Box>
    </Box>
  );
};

export default GridTable;
